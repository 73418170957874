.carouselContainer {
  margin: 0 auto;
  width: 90%;
  height: 100%;
  overflow: visible;

  .slick-list {
    overflow: visible;
  }

    @media (max-width: 767px) {
      width: 100%;
      height: auto;
    }
  
}

.forwardButton {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 40px;
    width: 40px;
    right: 10px;
    background: rgb( 88,204,2 );
    box-shadow: 0 8px 0 black, 0 8px 0 rgb( 88,204,2 );
    border-radius: 50%/50%;
    top: 50%;
    transform: translateY(-50%);
}

.slick-list {
    border-radius: 20px !important;
}

.slick-slider .slick-track, 
.slick-slider .slick-list {
    border-radius: 20px !important;
    @media (max-width: 767px) {
      border-radius: 10px !important;
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px);
    }
    60% {
      transform: translateY(-7px);
    }
  }

.bouncingSlide {
    animation: bounce 1s;

    @media (max-width: 767px) {
      animation: bounce 1s ease-in-out;
    }
}

