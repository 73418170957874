.homeContainer {
    height: 100%;
    max-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.textContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 120px;
    padding-bottom: 30px;

    @media (max-width: 767px) {
        width: 100%;
        padding-left: 20px;
        padding-bottom: 10px;
        
      }
}

@keyframes expand {
    0% {
        mmargin-left: 100px;
    }
    100% {
        margin-left: 0px;
    }
}

@keyframes typewriter {
    from { width: 0; }
    to { width: 100%; }
}

.typewriter {
    overflow: hidden;
    border-right: 0.15em solid skyblue;
    white-space: nowrap;
    width: 0;
    animation: typewriter 4s steps(20, end) forwards,
               blink 0.75s step-end infinite;
    @media (max-width: 767px) {
        font-size: 36px;
    }
}

.empty {
    display: flex;
}

.typewriter.hid-cursor::after {
    animation: blink 0.75s steps(44, end) infinite;
    border-right: 0;
}

@keyframes blink {
    50% { border-color: transparent; }
}

.header {
    font-size: 72px;
    font-weight: 300;
    max-width: fit-content;

    @media (max-width: 767px) {
        font-size: 36px;
      }
}

.subsection1 {
    font-size: 40px;
    font-weight: 100;
}
.subsection2 {
    font-size: 36px;
    font-weight: 100;
}
.subsection3 {
    font-size: 32px;
    font-weight: 100;
}
.subsection4 {
    font-size: 28px;
    font-weight: 100;
}

.subsection1,
.subsection2,
.subsection3,
.subsection4 {
  @media (max-width: 767px) {
    font-size: 18px;
  }
}

.carouselContainer {
    width: 50%;
    display: flex;
    flex-direction: column;

    @media (max-width: 767px) {
        width: 100%;
      }
}
