.mainContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    position: relative;
    gap: 20px;
    
    &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 4px;
        height: 0%;
        background-color: #455c5a;
        border-radius: 4px;
        animation: hover 1.5s ease-in forwards;
    }

    @media (max-width: 767px) {
        &::after {
            width: 0;
        }
    }
}

@keyframes hover {
    0% {
        height: 0%;
    }
    100% {
        height: 100%
    }
}

@keyframes hoverLeave {
    0% {
        height: 100%;
    }
    100% {
        height: 0%
    }
}

.sectionNameContainer {
    display: flex;
    cursor: pointer;
    position: relative;
    width: 80%;
    padding: 5px 10px;
    font-size: 20px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 0%;
        width: 2px;
        background-color: #455c5a;
        animation: hoverLeave 0.5s ease-in forwards;
    }

    &:hover::before {
        animation: hover 0.5s ease-in forwards;
    }

}

.activeSectionNameContainer {
    display: flex;
    cursor: pointer;
    position: relative;
    width: 80%;
    padding: 5px 10px;
    font-size: 20px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 2px;
        background-color: #455c5a;
    }
}