.projectsPageContainer {
    display: flex;
    
    @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
    }
}

.pageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    height: 80vh;
    width:100%;
    padding-left: 50px;
    padding-right: 50px;
    overflow-y: auto;

    button {
        display: none;
        margin: 15px;
        font-size: 24px;
        background: none;
        border: none;

        @media (max-width: 767px) {
            display: inline;
        }
    }

    @media (max-width: 767px) {
        padding-left: 15px;
        padding-right: 15px;
        height: 100%;
    }

}

.lottieCoding {
    width: 60%;
    height: 60%;

    @media (max-width: 767px) {
        width: 90%;
        height: 90%;
    }
}

.sidebarContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20%;
    height: 100%;
    margin-left: 100px;
    overflow-y: hidden;

    @media (max-width: 767px) {
        width: 100%;
        margin-left: 0;
    }
}